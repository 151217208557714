<template>
  <v-app>
    <template v-if="!isLoading">
      <v-main>
        <!-- Header -->
        <v-row dense :class="isMobile ? 'mt-9 mr-3 mb-3 ml-3' : 'mt-7 mr-3 mb-7 ml-3'">
          <v-col cols="3" class="flex-container-start">
            <a class="header-logo" href="https://www.twitch.tv" target="_blank"><img src="/imgs/twitch-purple.png" /></a>
          </v-col>
          <v-col cols="9" class="flex-container-end">
            <span class="header-title">{{ language === '日本語' ? campaignName + ' ' + $vuetify.lang.t('$vuetify.welcome_to') : $vuetify.lang.t('$vuetify.welcome_to') + ' ' + campaignName }}</span>
            <span class="header-language">{{ language }}</span>
            <span class="header-language-icon"><v-icon disabled>mdi-web</v-icon></span>
          </v-col>
        </v-row>
        <!-- /Header -->
        <!-- Hero -->
        <v-row dense :class="isMobile ? '' : 'ml-5 mr-5'">
          <v-col cols="12">
            <v-card :style="isMobile ? 'background-color: unset !important; box-shadow: none !important;' : 'background-color: black !important'">
              <template v-if="viewChannelsCampaign.landing_banner_image_url !== null">
                <v-img class="white--text align-end" :src="viewChannelsCampaign.landing_banner_image_url" :style="isMobile ? '' : 'border-radius: 50px'">
                  <v-card-title>
                    <v-row dense :class="isMobile ? '' : 'ml-2 mr-2'">
                      <v-col cols="6" class="flex-container-start">
                        <template v-if="viewChannelsCampaign.landing_sign_up_enabled === true && campaignStarted === false">
                          <v-btn class="hero-button" rounded color="primary" dark x-large @click="signUp">{{ $vuetify.lang.t('$vuetify.sign_up') }} ↗</v-btn>
                        </template>
                      </v-col>
                      <v-col cols="6" class="flex-container-end">
                        <template v-if="viewChannelsCampaign.landing_dates !== null">
                          <v-btn class="hero-dates" rounded color="primary" dark x-large text>{{ isMobile ? `${viewChannelsCampaign.landing_dates}` : `${campaignName} | ${viewChannelsCampaign.landing_dates}` }}</v-btn>
                        </template>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-img>
              </template>
              <template v-else>
                <v-img class="white--text center" src="/imgs/hero-placeholder.png">
                  <v-card style="background-color: unset !important; box-shadow: none !important">
                    <v-img class="white--text center flex-container-center" src="/imgs/hero-placeholder.png">
                      <v-card-title class="white--text align-center">
                        <span class="hero-name">{{ campaignName }}</span>
                      </v-card-title>
                    </v-img>
                    <v-card-actions class="white--text align-end">
                      <template v-if="viewChannelsCampaign.landing_sign_up_enabled === true && campaignStarted === false">
                        <v-btn class="hero-name-button" rounded color="primary" dark x-large @click="signUp">{{ $vuetify.lang.t('$vuetify.sign_up') }} ↗</v-btn>
                      </template>
                    </v-card-actions>
                  </v-card>
                </v-img>
              </template>
            </v-card>
          </v-col>
        </v-row>
        <!-- /Hero -->
        <!-- Main -->
        <v-row dense :class="isMobile ? 'mt-5' : 'mt-10'">
          <v-col cols="12">
            <!-- Prizes -->
            <v-card class="prizes-container">
              <v-card-title :class="'no-word-break prizes-title ' + (isMobile ? '' : 'mt-10 ml-10 mr-10 mb-10')" v-html="viewChannelsCampaign.landing_custom_subtitle" />
              <template v-if="hasPrizes">
                <v-card :class="'prizes-carousel ' + (isMobile ? 'mb-2' : 'mt-2 pl-10 pr-10')" min-width="100%">
                  <carousel :loop="true" :autoplay="true" :autoplayTimeout="Number((viewChannelsCampaign.landing_carousel_interval ?? 6) + '000')" :autoplayHoverPause="true" :perPage="1" paginationActiveColor="#efefef" paginationColor="#000000">
                    <slide v-for="(slide, i) in slides" :key="i"><img :src="slide.image" width="100%" height="auto" /></slide>
                  </carousel>
                </v-card>
              </template>
            </v-card>
            <!-- /Prizes -->
            <div class="exp-container mb-10">
              <!-- Tiers -->
              <template v-if="!isMobile">
                <v-spacer class="mt-10"></v-spacer>
              </template>
              <template v-for="(tier, index) in tiers">
                <template>
                  <div :key="viewChannelsCampaign.slug + '::' + index">
                    <v-card :class="'exp-card ' + (isMobile ? 'mt-5' : 'mt-10 ml-10 mr-10 mb-5')">
                      <div class="exp-card-level ml-4" :style="'color:' + tierColors[index] + ' !important'">{{ language === '中文 繁體' ? getIndex(tier) + $vuetify.lang.t('$vuetify.level') : $vuetify.lang.t('$vuetify.level') + (language === '日本語' ? '' : ' ') + getIndex(tier) }}</div>
                      <v-card-title :class="'exp-card-tier ' + (isMobile ? 'mb-3' : 'mb-8')" :style="'color:' + tierColors[index] + ' !important'">
                        {{ new Intl.NumberFormat('en-US').format(tier) }}
                        <span :class="'exp-card-exp ' + (isMobile ? 'mt-5 ml-1' : 'mt-10 ml-5')">EXP</span>
                      </v-card-title>
                      <v-card-subtitle class="no-word-break exp-card-prize mb-1" style="max-width: 100%">{{ getPrizeName(getIndex(tier)) }}</v-card-subtitle>
                    </v-card>
                  </div>
                </template>
              </template>
              <!-- /Tiers -->
              <!-- FAQ -->
              <v-card :class="'faq-container ' + (isMobile ? 'mt-10' : 'mt-10 pt-10')">
                <template v-if="!isMobile">
                  <v-spacer class="mt-10"></v-spacer>
                </template>
                <v-card-title :class="'no-word-break faq-title ' + (isMobile ? 'mt-3 mb-3' : 'mt-10 mb-10')">{{ viewChannelsCampaign.landing_faqs_title }}</v-card-title>
                <template>
                  <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
                      <v-expansion-panel-header class="no-word-break faq-header" expand-icon="mdi-arrow-top-right-thick">{{ faq.question }}</v-expansion-panel-header>
                      <v-expansion-panel-content class="no-word-break faq-content" v-html="faq.answer.replaceAll('#EEEEEE', '#c0c0c0')" />
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
                <template v-if="!isMobile">
                  <v-spacer class="mt-10"></v-spacer>
                </template>
              </v-card>
              <!-- /FAQ -->
            </div>
          </v-col>
        </v-row>
        <!-- /Main -->
      </v-main>
      <!-- Footer -->
      <v-footer class="footer-container">
        <v-row dense>
          <v-col cols="2" class="footer-logo">
            <a href="https://www.twitch.tv" target="_blank"><img src="/imgs/twitch-glitch-white.png" /></a>
          </v-col>
          <v-col cols="10" style="display: flex; align-items: flex-end; justify-content: flex-end">
            <span class="footer-trade-mark">&copy; {{ new Date().getFullYear() }} Twitch Interactive Inc</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4">
            <div class="footer-links">
              <a class="footer-terms-of-service" href="https://www.twitch.tv/p/en/legal/terms-of-service/" target="_blank">{{ $vuetify.lang.t('$vuetify.terms') }}</a>
              <a class="footer-privacy-policy" href="https://www.twitch.tv/p/en/legal/privacy-notice/" target="_blank">{{ $vuetify.lang.t('$vuetify.privacy_policy') }}</a>
            </div>
          </v-col>
          <v-col cols="8" class="footer-sponsor"
            ><span class="footer-partner">{{ $vuetify.lang.t('$vuetify.develop_by') }}</span></v-col
          >
        </v-row>
      </v-footer>
      <!-- /Footer -->
    </template>
    <template v-else>
      <v-overlay>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </template>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { sortBy, isEmpty } from 'lodash'
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      campaignTabs: 0,
      page: 1,
      pageSize: 10,
      showSearch: '',
      showLoggedIn: true,
      showNotLoggedIn: true,
      showOverlayUsed: true,
      showOverlayNotUsed: true,
      showMenu: false,
      policy: false,
      viewChannelsDialog: false,
      viewChannelsCampaign: null,
      newChannelDialog: false,
      newChannelCampaign: null,
      newChannelSearch: '',
      importText: '',
      isLoading: true,
      isLoadingCoupons: true,
      isSearch: true,
      overlay: false,
      startDateMenu: false,
      endDateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      name: '',
      includeSubs: true,
      includeBits: true,
      raidTimezone: '',
      raidTag: '',
      raidLevel: '',
      tierColors: ['#bfabff', '#f093f9', '#ea7078', '#beff00', '#beff00', '#fdb210', '#ff6905', '#be0078', '#9146ff', '#1e69ff', '#57bee6', '#befae1'].reverse(),
      tier1: 0,
      tier2: 0,
      tier3: 0,
      tier4: 0,
      tier5: 0,
      tier6: 0,
      tier7: 0,
      tier8: 0,
      tier9: 0,
      tier10: 0,
      tier11: 0,
      tier12: 0,
      rules: [(v) => v < 2147483647 || 'Max should not be above 2,147,483,647'],
      link: process.env.VUE_APP_BASE_API_URL,
      raidLevels: [
        { name: 'Partner only', value: 'partner' },
        { name: 'Partner & Affiliate only', value: 'affiliate' },
        { name: 'All', value: 'all' }
      ],
      coupons: [],
      couponsSearch: '',
      couponsPage: 1,
      couponsPerPage: 10,
      couponsPageCount: 0,
      couponSuccess: false,
      couponsDialogDelete: false,
      couponsHeaders: [
        { text: 'Coupon Code', value: 'code', align: 'start' },
        { text: 'User ID', value: 'user_id', align: 'start' },
        { text: 'User Name', value: 'user_name' },
        { text: 'Digital Reward Name', value: 'name' },
        { text: 'Digital Reward Mechanics', value: 'mechanics' },
        { text: 'Tier', value: 'tier_level' },
        { text: 'Send (Live/Post)', value: 'live' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null
      },
      slides: [],
      faqs: [],
      showIndex: -1,
      screenHeight: 0,
      screenWidth: 0,
      browserSupported: true,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      language: 'English',
      languages: ['English', 'Português', 'Français', 'Español', 'Deutsch', '日本語', '中文 繁體'] // English, Portuguese, French, Spanish, German, Japanese, Chinese
    }
  },
  computed: {
    faqCount() {
      return this.faqs.length
    },
    tierCount() {
      return this.viewChannelsCampaign.tiers.length
    },
    tiers() {
      const tiers = [...this.viewChannelsCampaign.tiers].reverse()
      return tiers
    },
    campaignStarted() {
      return new Date() > new Date(this.viewChannelsCampaign.start_date)
    },
    isMobile() {
      return this.screenHeight <= 900
    },
    hasPrizes() {
      return this.viewChannelsCampaign.landing_prize1_image_url !== null || this.viewChannelsCampaign.landing_prize2_image_url !== null || this.viewChannelsCampaign.landing_prize3_image_url !== null
    },
    campaignName() {
      return !isEmpty(this.viewChannelsCampaign?.local_name) ? this.viewChannelsCampaign?.local_name : this.viewChannelsCampaign?.name
    }
  },
  methods: {
    ...mapActions(['getCampaignLanding', 'signin']),
    getIndex(tier) {
      return this.viewChannelsCampaign.tiers.indexOf(tier) + 1
    },
    getPrizeName(level) {
      switch (level) {
        case 12:
          return this.viewChannelsCampaign.landing_prize12_name
        case 11:
          return this.viewChannelsCampaign.landing_prize11_name
        case 10:
          return this.viewChannelsCampaign.landing_prize10_name
        case 9:
          return this.viewChannelsCampaign.landing_prize9_name
        case 8:
          return this.viewChannelsCampaign.landing_prize8_name
        case 7:
          return this.viewChannelsCampaign.landing_prize7_name
        case 6:
          return this.viewChannelsCampaign.landing_prize6_name
        case 5:
          return this.viewChannelsCampaign.landing_prize5_name
        case 4:
          return this.viewChannelsCampaign.landing_prize4_name
        case 3:
          return this.viewChannelsCampaign.landing_prize3_name
        case 2:
          return this.viewChannelsCampaign.landing_prize2_name
        default:
          return this.viewChannelsCampaign.landing_prize1_name
      }
    },
    setLanguage() {
      const language = this.viewChannelsCampaign.country
      let preferredLanguage
      if (['BR'].includes(language)) {
        preferredLanguage = 'pt'
        this.language = 'Português'
      } else if (['FR'].includes(language)) {
        preferredLanguage = 'fr'
        this.language = 'Français'
      } else if (['ES', 'MX'].includes(language)) {
        preferredLanguage = 'es'
        this.language = 'Español'
      } else if (['DE'].includes(language)) {
        preferredLanguage = 'de'
        this.language = 'Deutsch'
      } else if (['JP'].includes(language)) {
        preferredLanguage = 'ja'
        this.language = '日本語'
      } else if (['TW', 'HK'].includes(language)) {
        preferredLanguage = 'zh'
        this.language = '中文 繁體'
      } else {
        preferredLanguage = 'en'
        this.language = 'English'
      }
      this.$vuetify.lang.current = preferredLanguage
    },
    dictionaryToArray(faqs) {
      var array = []
      for (const key in faqs) {
        const question = key.includes('_') ? key.replaceAll('_', '.') : key
        const data = question.split('|')
        const temp = {
          position: Number(data[0]),
          question: data[1],
          answer: faqs[key]
        }
        array.push(temp)
      }
      const sorted = sortBy(array, ['position'], ['asc'])
      return sorted
    },
    setShowIndex(index) {
      this.showIndex = index
    },
    onScreenResize() {
      window.addEventListener('resize', () => {
        this.updateScreenHeight()
        this.updateScreenWidth()
      })
    },
    updateScreenHeight() {
      this.screenHeight = window.innerHeight
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
    signUp() {
      sessionStorage.login = 'signUp'
      sessionStorage.campaign = JSON.stringify(this.viewChannelsCampaign)
      this.signin()
    },
    checkLandingEnabled() {
      if (this.viewChannelsCampaign.landing_page_enabled === false) {
        this.$router.push({
          name: 'home'
        })
      }
    },
    preloadImages() {
      if (this.viewChannelsCampaign) {
        if (this.viewChannelsCampaign.landing_prize1_image_url !== null) {
          const img = new Image()
          img.src = this.viewChannelsCampaign.landing_prize1_image_url
          this.slides.push({
            image: img.src
          })
        }
        if (this.viewChannelsCampaign.landing_prize2_image_url !== null) {
          const img = new Image()
          img.src = this.viewChannelsCampaign.landing_prize2_image_url
          this.slides.push({
            image: img.src
          })
        }
        if (this.viewChannelsCampaign.landing_prize3_image_url !== null) {
          const img = new Image()
          img.src = this.viewChannelsCampaign.landing_prize3_image_url
          this.slides.push({
            image: img.src
          })
        }
      }
    }
  },
  mounted() {
    if (process.env.VUE_APP_NO_PROD_REDIRECT !== 'true') {
      location.replace('https://glitch-exp.com')
    }
    this.updateScreenHeight()
    this.updateScreenWidth()
    this.onScreenResize()
    this.isLoading = true
    if (this.viewChannelsCampaign == null) {
      this.getCampaignLanding(this.$route.params.slug).then((result) => {
        this.viewChannelsCampaign = result
        this.faqs = this.dictionaryToArray(this.viewChannelsCampaign.faqs ?? {})
        this.checkLandingEnabled()
        this.setLanguage()
        this.preloadImages()
        this.isLoading = false
      })
    } else {
      this.checkLandingEnabled()
      this.setLanguage()
      this.isLoading = false
    }
  }
}
</script>

<style>
body {
  background: #121212 !important;
}

.v-btn__content {
  color: inherit !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  text-transform: inherit !important;
  margin-left: inherit !important;
  margin-right: inherit !important;
  font-family: 'RoobertRegular' !important;
}
</style>

<style lang="scss" scoped>
.flex-container-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.flex-container-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-container-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.no-word-break {
  word-break: unset;
  word-wrap: unset;
  overflow-wrap: unset;
  hyphens: unset;
  -webkit-hyphens: unset;
  -moz-hyphens: unset;
  -ms-hyphens: unset;
}

.header-logo {
  margin-left: 40px;
  @media only screen and (max-height: 900px) {
    margin-left: 4px;
    img {
      width: 54px !important;
      height: 18px !important;
    }
  }
}

.header-title {
  margin-right: 55px;
  color: #f0f0ff;
  font: 600 20px/18px 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    margin-right: 15px;
    font: 600 12px/18px 'RoobertRegular', sans-serif;
  }
}

.header-language {
  margin-right: 15px;
  color: #f0f0ff;
  font: 600 20px/18px 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    margin-right: 5px;
    font: 600 12px/18px 'RoobertRegular', sans-serif;
  }
}

.header-language-icon {
  margin-right: 40px;

  @media only screen and (max-height: 900px) {
    margin-right: 0px;
  }
}

.hero-name {
  color: white;
  font: 600 150px/93% 'RoobertRegular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  margin-top: -200px;

  @media only screen and (max-height: 900px) {
    margin-top: -50px;
    font: 600 35px/93% 'RoobertRegular', sans-serif;
  }
}

.hero-button {
  text-transform: capitalize !important;
  color: black !important;
  margin-bottom: 10px;
  border-radius: 55px !important;
  padding: 30px !important;
  letter-spacing: 0.5px !important;
  font-size: 30px;
  font-weight: 600 !important;

  @media only screen and (max-height: 900px) {
    margin-bottom: -10px;
    height: 10px !important;
    min-width: 30px !important;
    width: 70px !important;
    padding: 10px !important;
    font-size: 10px;
  }
}

.hero-button:hover {
  background: #7d5bbe !important;
}

.hero-dates {
  text-transform: none !important;
  background-color: #000000 !important;
  margin-bottom: 10px;
  border-radius: 50px !important;
  padding: 30px !important;
  letter-spacing: 0.5px !important;
  font-size: 30px;
  font-weight: 500 !important;
  cursor: text;

  @media only screen and (max-height: 900px) {
    margin-bottom: -14px;
    height: 10px !important;
    min-width: 30px !important;
    width: 100px !important;
    padding: 10px !important;
    font-weight: 600 !important;
    font-size: 10px;
  }
}

.hero-name-button {
  margin-left: 10px;
  margin-top: -150px;
  margin-bottom: 20px;

  text-transform: none !important;
  color: black !important;
  border-radius: 55px !important;
  padding: 30px !important;
  letter-spacing: 0.5px !important;
  font-size: 30px;
  font-weight: 600 !important;

  @media only screen and (max-height: 900px) {
    margin-left: 0px;
    margin-bottom: 0px;
    height: 10px !important;
    min-width: 30px !important;
    padding: 15px !important;
    font-size: 13px;
  }
}

.prizes-container {
  background-color: #222 !important;
  border-radius: 100px 100px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: unset !important;

  @media only screen and (max-height: 900px) {
    border-radius: 25px 25px 0 0;
  }
}

.prizes-title {
  text-align: center;
  color: #f0f0ff;
  font: 600 90px/93% 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 600 40px/93% 'RoobertRegular', sans-serif;
  }
}

.prizes-carousel {
  background-color: #222 !important;
  border-radius: 50px !important;
  box-shadow: unset !important;

  @media only screen and (max-height: 900px) {
    border-radius: 4px !important;
  }
}

.exp-container {
  background-color: #222 !important;
  display: flex;
  flex-direction: column;
}

.exp-card {
  background-color: #222 !important;
  box-shadow: unset !important;
  padding-left: 140px !important;
  padding-right: 140px !important;

  @media only screen and (max-height: 900px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.exp-card-level {
  font: 600 45px/88% 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 600 25px/88% 'RoobertRegular', sans-serif;
  }
}

.exp-card-tier {
  font: 600 120px/88% 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 600 50px/88% 'RoobertRegular', sans-serif;
  }
}

.exp-card-exp {
  font: 600 70px/61.6px 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 600 25px/88% 'RoobertRegular', sans-serif;
  }
}

.exp-card-prize {
  color: white !important;
  font: 600 65px/97.5% 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 600 20px/88% 'RoobertRegular', sans-serif;
  }
}

.faq-container {
  background-color: black !important;
  border-radius: 100px 100px 0 0 !important;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  box-shadow: unset !important;
  padding-left: 180px !important;
  padding-right: 180px !important;

  @media only screen and (max-height: 900px) {
    border-radius: 25px 25px 0 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.faq-title {
  font: 700 60px/47.23px 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 700 25px/37.23px 'RoobertRegular', sans-serif;
  }
}

.faq-header {
  background-color: #000000 !important;
  color: #f0f0ff;
  font: 600 39px/130% 'RoobertRegular', sans-serif;
  border-top: 3px solid white !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;

  @media only screen and (max-height: 900px) {
    font: 600 20px/130% 'RoobertRegular', sans-serif;
    border-top: 2px solid white !important;
  }
}
.faq-header:hover {
  color: #7d5bbe;
}

.faq-content {
  background-color: #000000 !important;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 60px;
  color: #c0c0c0 !important;
  text-align: left;
  font: 600 35px/125% 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 600 15px/125% 'RoobertRegular', sans-serif;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.footer-container {
  background-color: black !important;
  margin-top: 145px;
  margin-bottom: 65px;
  margin-left: 30px;
  margin-right: 30px;
  display: block;

  @media only screen and (max-height: 900px) {
    margin-top: -40px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.footer-logo {
  margin-top: -85px;

  @media only screen and (max-height: 900px) {
    margin-top: 50px;

    img {
      width: 25px;
    }
  }
}

.footer-trade-mark {
  margin-bottom: -10px;
  color: #ffffff;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 500 10px/37.23px 'RoobertRegular', sans-serif;
  }
}

.footer-links {
  @media only screen and (max-height: 900px) {
    display: flex;
    flex-direction: column;
  }
}

.footer-terms-of-service {
  text-decoration: none;
  color: #ffffff;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 500 10px/37.23px 'RoobertRegular', sans-serif;
  }
}

.footer-privacy-policy {
  margin-left: 65px;
  text-decoration: none;
  color: #ffffff;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 500 10px/37.23px 'RoobertRegular', sans-serif;
    margin-top: -16px;
    margin-left: 0px;
  }
}

.footer-sponsor {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media only screen and (max-height: 900px) {
    align-items: center !important;
  }
}

.footer-partner {
  text-align: right;
  color: #b3b3b3;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    font: 500 10px/21.23px 'RoobertRegular', sans-serif;
  }
}
</style>
